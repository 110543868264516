<template>
  <b-form v-if="citem!=null && item!=null" id="resDetForm" @submit.stop.prevent>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('res.euml')" label-for="resDetEditMsg" label-cols="12" label-cols-sm="3">
          <b-textarea id="resDetEditMsg" :readonly="!(isUser && inEdit)" rows="3" v-model="item.userMessage" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('res.erml')" label-for="resDetEditCust" label-cols="12" label-cols-sm="3">
          <b-textarea id="resDetEditCust" :readonly="!(!isUser && inEdit && canCustEditMsg)" rows="3" v-model="item.customerMessage" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="!isUser">
      <b-col>
        <b-form-group :label="$t('res.einl')" label-for="resDetEditNote" label-cols="12" label-cols-sm="3">
          <b-textarea id="resDetEditNote" :readonly="!inEdit" rows="3" v-model="item.customerNote" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="!isUser">
      <b-col>
        <b-form-group :label="$t('res.ena')" label-for="resDetEditTab" label-cols="12" label-cols-sm="3">
          <TablesDropdown id="resDetEditTab" form="resDetForm" :disabled="!inEdit" :value="item.tableUuid4" @input="item.tableUuid4=$event.tableUuid4" :placeHolder="item.tableName" ref="tablesDd" />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row class="justify-content-end mr-n2 mr-sm-n3">
      <b-col :class="ac">
        <b-button type="button" variant="primary" class="btn-block" id="resDetEditFormBack"
            @click="onBack()">
          <Icon type="b" /> {{ $t('btn.b') }}
        </b-button>
      </b-col>
      <b-col :class="ac" v-if="inEdit">
        <b-button type="button" variant="primary" class="btn-block" id="resDetEditFormReset" :disabled="!isEdited"
            @click="onReset()">
          <Icon type="r" /> {{ $t('btn.r') }}
        </b-button>
      </b-col>
      <b-col :class="ac" v-if="inEdit">
        <b-button type="button" variant="secondary" class="btn-block" id="resDetEditFormUpdate" :disabled="!isEdited"
            @click="onUpdate()">
          <Icon type="u" /> {{ $t('btn.u') }}
        </b-button>
      </b-col>
      <b-col :class="ac" v-if="!inEdit">
        <b-button type="button" variant="secondary" class="btn-block" id="resDetEditFormEdit" :disabled="!(canUserEdit || canCustEdit)"
            @click="onEdit()">
          <Icon type="e" /> {{ $t('btn.e') }}
        </b-button>
      </b-col>
    </b-form-row>

    <ModalYesNoGeneric id="resDetEditBtnAct" ref="modal" :title="modalHeaderText"
        :btnTextReset="$t('btn.a')" btnIconReset="a"
        :btnTextSubmit="$t('btn.u')" btnIconSubmit="u" btnVariantSubmit="secondary"
        @reset="onClose()" @submit="onConfirmation()" />
  </b-form>
</template>

<script>
import Icon from '@/components/Icon';
import ModalYesNoGeneric from '@/components/ModalYesNoGeneric';
import { utcToZonedTime } from 'date-fns-tz';
import { REST } from '@/components/RestCall';
import TablesDropdown from '@/components/shared/TablesDropdown';

export default {
  data() {
    return {
      ac: {
        'col-auto': true,
        'mr-2': true,
        'mr-sm-3': true,
        'px-0': true
      },
      inEdit: false,
      item: null,
      origItem: null,
    }
  },
  mounted() {
    this.item = null;
    if (this.citem != null) {
      this.item = {...this.$store.state.oResDetails};
    }
  },
  watch: {
    citem() {
      this.item = {...this.$store.state.oResDetails};
    },
  },
  computed: {
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    canUserEdit() {
      return this.isUser && this.item !== null && ['CREATED','ACCEPTED'].includes(this.item.state);
    },
    canCustEditMsg() {
      return !this.isUser && this.item !== null && ['CREATED','ACCEPTED','LOCKED'].includes(this.item.state);
    },
    canCustEdit() {
      return !this.isUser && this.item !== null;// && ['CREATED','ACCEPTED','LOCKED','REJECTED','CANCELED','DISPUTED','ESCALATED','SHOWED','NOSHOWED'].includes(this.item.state);
    },
    isEdited() {
      return JSON.stringify(this.item) !== JSON.stringify(this.origItem);
    },
    modalHeaderText() {
      if (this.item.startAt == null) return '';
      return this.$t('res.et', [this.$d(utcToZonedTime(this.item.startAt+'Z', this.$store.state.settings.timezone),'as')]);
    },
    citem() {
      return this.$store.state.oResDetails;
    },
    iB() {
      return this.$store.getters.iB;
    },
  },
  methods: {
    onBack() {
      this.item = null;
      this.origItem = null;
      this.inEdit = false;
      this.$router.go(-1); // return from where we came from
    },
    onEdit() {
      this.$refs.tablesDd.loadItems();
      this.inEdit = true;
      this.origItem = {...this.item};
    },
    onReset() {
      this.item = {...this.origItem};
      this.origItem = null;
      this.inEdit = false;
    },
    onUpdate() {
      this.$bvModal.show('resDetEditBtnAct');
    },
    onConfirmation() {
      this.origItem = null;
      this.inEdit = false;
      this.$bvModal.hide('resDetEditBtnAct');
      let obj = { action: 'EDIT' };
      if (this.isUser) {
        obj['userMessage'] = this.item.userMessage;
      } else {
        obj['customerMessage'] = this.item.customerMessage;
        obj['customerNote'] = this.item.customerNote;
        obj['tableUuid4'] = this.item.tableUuid4;
      }
      REST.patch('/reservations/' + this.item.uuid4, obj)
          .then(resp => {
            this.$store.commit('showSuccess', resp.d.message);
            if (this.saveResult) {
              this.$store.commit('setResDetails', resp.d.data);
            }
          }).catch(e => {
            this.$store.commit('showWarn',e.message);
          })
    },
    onClose() {
      this.$bvModal.hide('resDetEditBtnAct');
    },
  },
  components: {
    TablesDropdown,
    ModalYesNoGeneric,
    Icon,
  },
}
</script>
